const btnStyle = {
  background: '#ff5733',
  color: '#fff',
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: '4.5em',
  right: '1em',
  borderRadius: '50%',
  width: '32px',
  height: '32px',
};

const shareStyle = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: '1em',
  right: '1em',
};

const logoStyle = {
  position: 'absolute',
  top: '1em',
  left: '0.5em',
  width: '45px',
  height: '45px',
  color: '#ff5733',
  backgroundColor: '#fff',
  padding: '10px 10px',
  borderRadius: '50%',
};

export { btnStyle, logoStyle, shareStyle };
