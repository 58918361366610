import React, { useEffect, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import Search from '../../components/Search';
import Header from '../../components/Header';
import Analytics from '../../components/Analytics';
import './dashboard.css';
import { AxiosAPI } from '../../config';
import { Box, CircularProgress, Typography } from '@mui/material';
import Ticket from '../Tickets/Ticket';
import TripCard from './TripCard';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';

export default function Dashboard() {
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Organizer dashboard page',
      user,
    });
  }, []);
  useEffect(() => {
    AxiosAPI({
      url: '/organizers/dashboard',
      method: 'GET',
    })
      .then((d) => {
        if (d && d.code === 'ERR_BAD_REQUEST') {
          if (d.response.data.message == "You don't have a community") {
            window.location.assign('/organizer/create-account');
          }
          return {};
        }
        return d.data;
      })
      .then((d) => {
        const obj = {};
        obj.stat = {};
        obj.stat.tripsCount = d.organizedTrips.length;
        obj.stat.moneyGenerated = d.organizedTrips.reduce((prev, cur) => {
          return prev + cur.Tnx.reduce((p, n) => p + n.amount, 0);
        }, 0);
        obj.stat.bookings = d.organizedTrips.reduce((prev, cur) => {
          return prev + cur.Tnx.filter((t) => !t.isPaid).length;
        }, 0);
        obj.stat.paidBookings = d.organizedTrips.reduce((prev, cur) => {
          return prev + cur.Tnx.filter((t) => t.isPaid).length;
        }, 0);
        obj.upcomingTrips = d.organizedTrips.filter(
          (trip) => new Date().getTime() < new Date(trip.departure).getTime(),
        );
        obj.pastTrips = d.organizedTrips.filter(
          (trip) => new Date().getTime() >= new Date(trip.departure).getTime(),
        );
        setData(obj);
      })
      .catch(console.log);
  }, []);
  if (!data) {
    return (
      <Box sx={{ display: 'flex', py: 10, justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <div className="dashboard">
      <Header />
      <Analytics stat={data.stat} />
      {/* <Search /> */}
      {data.upcomingTrips.length + data.pastTrips.length == 0 ? (
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <Box
            sx={{
              width: '90%',
              background: '#F8F8F8',
              borderRadius: '11px',
              py: 4,
              mx: 2,
              textAlign: 'center',
              mb: 3,
            }}
          >
            <Typography variant="subtitle1">
              Create your first trip and
              <br />
              start booking easily!
            </Typography>
          </Box>
          <AddCircleIcon
            onClick={() => navigate('/create-trip')}
            sx={{
              fontSize: '60px',
              color: '#FF5733',
            }}
          />
        </Box>
      ) : (
        ''
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontSize: '18px',
            fontWeight: '600',
          }}
        >
          {data.upcomingTrips?.length ? 'Upcoming Trips' : ''}
        </h2>
      </div>
      {data.upcomingTrips.map((trip) => (
        <TripCard trip={trip} key={trip.id} />
      ))}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontSize: '18px',
            fontWeight: '600',
          }}
        >
          {data.pastTrips?.length ? 'Past Trips' : ''}
        </h2>
      </div>
      {data.pastTrips.map((trip) => (
        <TripCard trip={trip} key={trip.id} />
      ))}
      {data.upcomingTrips.length + data.pastTrips.length != 0 && (
        <div className="add-btn" onClick={() => navigate('/create-trip')}>
          <AddCircleIcon
            sx={{
              fontSize: '60px',
              color: '#FF5733',
            }}
          />
        </div>
      )}
    </div>
  );
}
