import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Utentic from './../../assets/icon/utentic.svg';
import { Menu, MenuItem, Stack, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
export default function Header() {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (route = false) => {
    setAnchorEl(null);
    if (route) {
      if (route === 'refresh') {
        window.location.reload();
      } else {
        navigate(`/${route}`);
      }
    }
  };

  return (
    <Box sx={{ flexGrow: 1, p: 0, m: 0, px: 2 }}>
      <AppBar position="static" elevation={0} sx={{ background: 'rgba(0,0,0,0)' }}>
        <Toolbar sx={{ p: 0 }}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => navigate('/')}
          >
            <img src={Utentic} width="30" height="530" alt="utentic logo" />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />

          <Stack direction="row" spacing={1}>
            {window.location.href.includes('tickets') && user ? (
              <Typography variant="body2" color="secondary.800" sx={{ py: 2 }}>
                {user.fullName}
              </Typography>
            ) : (
              <IconButton color="primary" onClick={() => navigate('/tickets')}>
                <Icon icon="ph:ticket" />
              </IconButton>
            )}
            <IconButton
              color="primary"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <Icon icon="bx:user" />
            </IconButton>
          </Stack>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => handleClose()}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {user && user.isOrganizer && (
              <MenuItem onClick={() => handleClose('dashboard')}>Dashboard</MenuItem>
            )}
            {!user && <MenuItem onClick={() => handleClose('login')}>Login</MenuItem>}
            {!user && <MenuItem onClick={() => handleClose('signup')}>Signup</MenuItem>}
            {!user && (
              <MenuItem onClick={() => handleClose('organizer/login')}>
                Organizer login
              </MenuItem>
            )}
            {!user && (
              <MenuItem onClick={() => handleClose('organizer/signup')}>
                Organizer signup
              </MenuItem>
            )}
            <MenuItem onClick={() => handleClose('refresh')}>Refresh</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
