import React, { useEffect } from 'react';
import { BsFillTelephoneFill } from 'react-icons/bs';
// import { ReactComponent as UtenticLogo } from '../../assets/icon/utentic-logo.svg';
import './style.css';
import ReactGA from 'react-ga4';

export default function ErrorPage() {
  const contactNumber = '0963742925';
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Page not found page',
    });
  }, []);
  return (
    <div className="error_container">
      <div className="main">
        <div className="err">
          <h1 className="num">4</h1>
          <h1 className="num" style={{ color: '#000' }}>
            0
          </h1>
          <h1 className="num">4</h1>
        </div>
        <h2
          style={{
            fontSize: '1.2em',
            fontWeight: 400,
          }}
        >
          Page Not Found ):
        </h2>
        <a
          className="icons"
          href={'tel:' + contactNumber}
          style={{ color: 'white', fontSize: '1.5em', padding: '0.5em' }}
        >
          <BsFillTelephoneFill />
        </a>
      </div>
    </div>
  );
}
