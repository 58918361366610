import React, { useEffect } from 'react';
import { AuthHeader } from '../components/AuthHeader';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import UtenticButton from '../../../components/Button/UtenticButton';
import { useNavigate } from 'react-router-dom';
import { signupValidationSchema } from './validation';
import { authAction } from '../../../redux/slice/auth';
import { useSelector, useDispatch } from 'react-redux';
import { MenuItem, Stack, TextField, useTheme } from '@mui/material';
import ReactGA from 'react-ga4';

const OrganizerSignupPage = () => {
  const navigate = useNavigate();
  const { user, isLoading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const theme = useTheme();
  const handleSubmit = (values) => {
    dispatch(authAction.INIT_ORGANIZER_AUTH(values));
  };
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Organizer auth signup page',
    });
  }, []);
  useEffect(() => {
    if (user) {
      navigate(`/verify?phone=${user.phoneNumber}&next=createCommunity`);
    }
  }, [user]);

  return (
    <>
      <AuthHeader />
      <div className="w-full px-[26px] mx-auto">
        <div className="w-full mt-2 flex flex-col justify-center items-center">
          <div className="w-full sm:w-2/3 md:w-1/3  my-4">
            <p className="text-[24px] font-light">
              Create your organizer <br /> account
            </p>
          </div>
          <Formik
            initialValues={{
              fullName: '',
              phoneNumber: '',
              age: '',
              gender: 'MALE',
            }}
            onSubmit={(values) => {
              handleSubmit({ ...values, phoneNumber: `0${values.phoneNumber}` });
            }}
            validationSchema={signupValidationSchema}
          >
            {({ values, handleChange }) => (
              <Form className="w-full sm:w-2/3 md:w-1/3 flex flex-col gap-4">
                <div className="">
                  <Field
                    value={values.fullName}
                    name="fullName"
                    type="text"
                    placeholder="Full Name"
                    className="px-5 w-full h-[69px] bg-[#F5F5F5] hover:border-none outline-0"
                  />
                </div>
                <ErrorMessage name="fullName" component="div" className="error" />
                <div className="flex relative bg-gray-100">
                  <UtenticButton
                    btnType="button"
                    btnText={'+251'}
                    btnClassName={'w-[85px] h-[69px]'}
                  />
                  <Field
                    value={values.phoneNumber}
                    name="phoneNumber"
                    type="number"
                    pattern="[0-9]+"
                    placeholder="Phone Number"
                    className="w-full bg-[#F5F5F5] border-none hover:border-none px-5 h-[69px] outline-0"
                  />
                </div>
                <ErrorMessage name="phoneNumber" component="div" className="error" />
                <div className="w-full flex justify-between gap-4">
                  <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
                    <Field
                      value={values.age}
                      name="age"
                      type="number"
                      pattern="[0-9]"
                      placeholder="Age"
                      className="px-5 w-full h-[69px] bg-[#F5F5F5] hover:border-none outline-0"
                    />
                    <TextField
                      select
                      value={values.firstName}
                      name="gender"
                      placeholder="Gender"
                      onChange={handleChange}
                      defaultValue="MALE"
                      fullWidth
                      InputProps={{
                        style: { border: 'none' },
                      }}
                      className="px-5 bg-[#F5F5F5] border-none outline-0"
                    >
                      <MenuItem value="MALE">Male</MenuItem>
                      <MenuItem value="FEMALE">Female</MenuItem>
                    </TextField>
                  </Stack>
                </div>
                <ErrorMessage name="age" component="div" className="error" />
                <ErrorMessage name="gender" component="div" className="error" />
                <a
                  href="/organizer/login"
                  style={{
                    color: theme.palette.primary.main,
                    textDecoration: 'underline',
                  }}
                >
                  Already have an account?
                </a>
                <UtenticButton
                  btnType={'submit'}
                  btnText={isLoading ? 'Loading...' : 'submit'}
                  disabled={isLoading}
                  btnClassName={'w-full h-[69px] mt-10'}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default OrganizerSignupPage;
