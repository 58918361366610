import * as Yup from 'yup';

export const createAccountValidationSchema = Yup.object().shape({
  fullName: Yup.string()
    .trim()
    .min(3, 'Full Name must be at least 5 characters')
    .max(50, 'Full Name must be less than 50 characters')
    .required('Full Name is required'),
  phoneNumber: Yup.string()
    .trim()
    .matches(/^(9|7)\d{8}$/, 'Phone number must be in the format +251#########')
    .required('PhoneNumber is required'),
});

export const loginValidationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .trim()
    .matches(/^(9|7)\d{8}$/, 'Phone number must be in the format +251#########')
    .required('PhoneNumber is required'),
});
