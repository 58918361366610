import React, { useEffect, useState } from 'react';

import Header from '../../components/Header';
// import TCard from '../../components/TCard';
import BCard from '../../components/BCard';

import './style.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { AxiosAPI } from '../../config';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import ReactGA from 'react-ga4';

export default function BookingsPage() {
  const [trip, setTrip] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const tripId = new URLSearchParams(location.search).get('tripId');
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Booking page',
    });
  }, []);
  useEffect(() => {
    if (!tripId) {
      navigate('/404');
    } else {
      AxiosAPI({
        url: '/organizers/dashboard/' + tripId,
        method: 'GET',
      })
        .then((d) => d.data)
        .then((d) => {
          d.moneyGenerated = d.Tnx.reduce((p, n) => p + n.amount, 0);
          setTrip(d);
        })
        .catch((e) => {
          navigate('/404');
        });
    }
  }, []);
  if (!trip)
    return (
      <Box sx={{ display: 'flex', py: 10, justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  return (
    <div className="bookings-page">
      <Header />
      <div
        style={{
          margin: '1em',
        }}
      >
        {/* <TCard noActions={true} /> */}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div className="grid-box_">
          <div className="grid-box-num">{trip.Tnx.filter((t) => !t.isPaid).length}</div>
          <div className="grid-box-text">Bookings</div>
        </div>
        <div className="grid-box_">
          <div className="grid-box-num">{trip.Tnx.filter((t) => t.isPaid).length}</div>
          <div className="grid-box-text">Paid Bookings</div>
        </div>
        <div className="grid-box_">
          <div className="grid-box-num">{trip.moneyGenerated}</div>
          <div className="grid-box-text">Birr Generated</div>
        </div>
      </div>
      <div className="bcard-container">
        {trip.Tnx.map((tnx) => (
          <BCard tnx={tnx} key={tnx.id} />
        ))}
        {trip.Tnx.length === 0 ? (
          <Box
            sx={{
              width: '90%',
              background: '#F8F8F8',
              borderRadius: '11px',
              py: 4,
              mx: 2,
              textAlign: 'center',
              mb: 3,
            }}
          >
            <Typography variant="subtitle1">You don't have bookings yet!</Typography>
            <Button onClick={() => navigate('/dashboard')}>Go to dashboard</Button>
          </Box>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}
