import React, { useEffect } from 'react';
import { AuthHeader } from '../components/AuthHeader';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import UtenticButton from '../../../components/Button/UtenticButton';
import { useNavigate } from 'react-router-dom';
import {
  createAccountValidationSchema,
  loginValidationSchema,
  // validationSchema,
} from './validation';
import { authAction } from '../../../redux/slice/auth';
import { useSelector, useDispatch } from 'react-redux';
import { Alert, useTheme } from '@mui/material';
import ReactGA from 'react-ga4';
const UserAuthPage = () => {
  const navigate = useNavigate();
  const loginRoute = window.location.href.includes('login');
  const { user, isLoading, isError, error } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const theme = useTheme();
  const handleSubmit = (values) => {
    if (loginRoute) {
      dispatch(authAction.INIT_LOGIN(values));
    } else {
      dispatch(authAction.INIT_AUTH(values));
    }
  };
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Auth page',
    });
  }, []);
  useEffect(() => {
    if (user) {
      navigate('/verify?phone=' + user.phoneNumber);
    }
  }, [user]);
  return (
    <>
      <AuthHeader />
      <div className="w-full px-[26px] mx-auto">
        <div className="w-full mt-2 flex flex-col justify-center items-center">
          <div className="w-full sm:w-2/3 md:w-1/3 my-4">
            <p className="text-[24px] font-light">
              {loginRoute ? 'Login to your account;' : 'Create an account'}
            </p>
          </div>
          <Formik
            initialValues={{}}
            onSubmit={(values) => {
              handleSubmit({ ...values, phoneNumber: `0${values.phoneNumber}` });
            }}
            validationSchema={
              loginRoute ? loginValidationSchema : createAccountValidationSchema
            }
          >
            {({ values }) => (
              <Form className="w-full sm:w-2/3 md:w-1/3 flex flex-col gap-4">
                {!loginRoute && (
                  <>
                    <div className="">
                      <Field
                        value={values.firstName}
                        name="fullName"
                        type="text"
                        placeholder="Full Name"
                        className="px-5 w-full h-[69px] bg-[#F5F5F5] hover:border-none outline-0"
                      />
                    </div>
                    <ErrorMessage name="fullName" component="div" className="error" />
                  </>
                )}
                <div className="flex relative bg-gray-100">
                  <UtenticButton
                    btnType="button"
                    btnText={'+251'}
                    btnClassName={'w-[85px] h-[69px]'}
                  />
                  <Field
                    value={values.firstName}
                    name="phoneNumber"
                    type="number"
                    pattern="[0-9]+"
                    placeholder="Phone Number"
                    className="w-full bg-[#F5F5F5] border-none hover:border-none px-5 h-[69px] outline-0"
                  />
                </div>
                <ErrorMessage name="phoneNumber" component="div" className="error" />
                {loginRoute ? (
                  <a
                    href="/signup"
                    style={{
                      color: theme.palette.primary.main,
                      textDecoration: 'underline',
                    }}
                  >
                    Don't have an account?
                  </a>
                ) : (
                  <a
                    href="/login"
                    style={{
                      color: theme.palette.primary.main,
                      textDecoration: 'underline',
                    }}
                  >
                    Already have an account?
                  </a>
                )}
                {isError && <Alert variant='outlined' severity="error">{error ? error : 'Failed to process request'}</Alert>}

                <UtenticButton
                  btnType={'submit'}
                  btnText={isLoading ? 'Loading...' : 'Next'}
                  btnClassName={'w-full h-[69px] mt-20'}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default UserAuthPage;
