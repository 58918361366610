import { call, takeEvery, put } from 'redux-saga/effects';
import { authAction } from '../slice/auth';
import { AxiosAPI } from '../../config';

function* initAuth(action) {
  try {
    const { phoneNumber, fullName } = action.payload;
    let response = yield call(() =>
      AxiosAPI({
        url: '/users/auth',
        method: 'POST',
        data: {
          phoneNumber: phoneNumber.toString(),
          fullName,
        },
      }),
    );
    if (response.status === 201 || response.status === 200) {
      yield put(authAction.AUTH_SUCCESS(response.data));
    }
  } catch (e) {
    yield put(authAction.AUTH_ERROR());
    yield console.log('Error ---> ', e);
  }
}
function* getAuth(action) {
  try {
    const { } = action.payload;
    let response = yield call(() => AxiosAPI({ url: '/users/me', method: 'GET' }));
    if (response.status === 201 || response.status === 200) {
      yield put(authAction.GET_AUTH_SUCCESS(response.data.data));
    } else {
      yield put(authAction.GET_AUTH_ERROR());
    }
  } catch (e) {
    yield put(authAction.GET_AUTH_ERROR());
    yield console.log('Error ---> ', e);
  }
}

function* initVerifyOTP(action) {
  try {
    const { otp, phoneNumber } = action.payload;
    let response = yield call(() =>
      AxiosAPI({
        url: '/users/verify-otp',
        method: 'POST',
        data: {
          otp,
          phoneNumber,
        },
      }),
    );
    if (response.status === 200) {
      yield put(authAction.VERIFY_OTP_SUCCESS(response.data));
    } else {
      yield put(authAction.VERIFY_OTP_ERROR({ error: response.response.data.message }));
    }
  } catch (error) {
    yield put(authAction.VERIFY_OTP_ERROR({ error: "Error: " + JSON.stringify(error.message) }));
    yield console.log('Error ---> ', JSON.stringify(error.message));
  }
}

function* initLogin(action) {
  try {
    const { phoneNumber } = action.payload;
    let response = yield call(() =>
      AxiosAPI({
        url: '/users/login',
        method: 'POST',
        data: {
          phoneNumber: phoneNumber.toString(),
        },
      }),
    );

    if (response.status === 200) {
      yield put(authAction.LOGIN_SUCCESS(response.data));
    }
    else {
      yield put(authAction.LOGIN_ERROR({ error: response.response.data.message }));
    }
  } catch (e) {
    yield put(authAction.LOGIN_ERROR({ error: e.message }));
    yield console.log('Error ---> ', e);
  }
}

function* initOrganizerAuth(action) {
  try {
    const { phoneNumber, age, gender, fullName } = action.payload;
    let response = yield call(() =>
      AxiosAPI({
        url: '/organizers/auth',
        method: 'POST',
        data: {
          phoneNumber: phoneNumber.toString(),
          age,
          gender,
          fullName,
        },
      }),
    );

    if (response.status === 201) {
      yield put(authAction.ORGANIZER_AUTH_SUCCESS(response.data));
    }
  } catch (e) {
    yield put(authAction.ORGANIZER_AUTH_ERROR());
    yield console.log('Error ---> ', e);
  }
}

export default function* authSaga() {
  yield takeEvery(authAction.INIT_AUTH.type, initAuth);
  yield takeEvery(authAction.INIT_VERIFY_OTP.type, initVerifyOTP);
  yield takeEvery(authAction.INIT_LOGIN.type, initLogin);
  yield takeEvery(authAction.INIT_ORGANIZER_AUTH.type, initOrganizerAuth);
  yield takeEvery(authAction.INIT_GET_AUTH.type, getAuth);
}
