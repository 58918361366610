import React, { useEffect, useState } from 'react';
import Header from '../Home/Header';
import { Box, CircularProgress, Container, Stack, Typography } from '@mui/material';
import Ticket from './Ticket';
import { Icon } from '@iconify/react';
import { AxiosAPI } from '../../config';
import ReactGA from 'react-ga4';

function Tickets() {
  const [tickets, setTickets] = useState(null);
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Tickets page',
    });
  }, []);
  useEffect(() => {
    AxiosAPI({
      url: '/users/tickets',
      method: 'GET',
    })
      .then((d) => d?.data?.data)
      .then((d) => setTickets(d || []));
  }, []);
  return (
    <Container maxWidth="sm" sx={{ p: 0 }}>
      <Header />
      <Stack spacing={1}>
        <Typography variant="h5" sx={{ mb: 2, px: 2, pt: 4 }}>
          My Tickets
        </Typography>
        {!tickets && (
          <Box sx={{ display: 'flex', py: 10, justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        )}
        {tickets && tickets.length === 0 && (
          <Box
            sx={{
              textAlign: 'center',
              display: 'flex',
              pt: 10,
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Box sx={{ pb: 3 }}>
              <Icon icon="pepicons-print:ticket-circle-off" fontSize={70} />
            </Box>
            <Typography variant="h6"> You don't have tickets yet!</Typography>
            <Typography variant="subtitle">
              Your tickets will show up here when you book or pay for a trip.
            </Typography>
          </Box>
        )}
        {tickets && tickets.map((ticket) => <Ticket ticket={ticket} key={ticket.id} />)}
        {/* 
        <Ticket />
        <Ticket /> */}
      </Stack>
    </Container>
  );
}

export default Tickets;
