import { Alert, Box, Snackbar } from '@mui/material';
import React, { useState } from 'react';

function Share({ link, Comp }) {
  const [open, setOpen] = useState(false);
  const handleShare = (e) => {
    e.stopPropagation();
    const url = link;
    try {
      const shareData = {
        title: 'Utentic',
        text: 'You can share this trip on any social media!',
        url: url,
      };
      if (navigator.share && navigator.canShare(shareData)) {
        navigator.share(shareData);
      } else {
        navigator.clipboard.writeText(url);
        setOpen(true);
      }
    } catch (e) {
      console.log(e);
      alert('Unable to share');
    }
  };
  const handleClose = () => setOpen(false);
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          sx={{ width: '100%', zIndex: 100 }}
        >
          Share link copied!
        </Alert>
      </Snackbar>
      <Box onClick={handleShare}>{Comp}</Box>
    </>
  );
}

export default Share;
