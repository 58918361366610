import React from 'react';
import LazyImage from '../../components/LazyImage';
import { Box, Button, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { Icon } from '@iconify/react';
import { fDate2 } from '../../utils';
import { useNavigate } from 'react-router-dom';

function Ticket({ ticket }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { trip, isPaid, tnxRef } = ticket;
  const image = trip.image.length
    ? trip.image[Math.floor(Math.random() * trip.image.length)]
    : 'https://picsum.photos/200';
  return (
    <Box sx={{ px: 1 }}>
      <Box sx={{ my: 1, p: 1, background: '#F8F8F8', borderRadius: '11px', height: 120 }}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Box
            sx={{ width: 130, height: '100%', borderRadius: '8px', overflow: 'hidden' }}
          >
            <LazyImage src={image} ratio="1/1" />
          </Box>
          <Stack sx={{ width: '100%', height: 100 }}>
            <Typography variant="h6" fontWeight="bold" fontSize={18}>
              {trip.name}
            </Typography>
            <Typography variant="body1" color="secondary.800" fontSize={12}>
              {fDate2(trip?.departure)} - {fDate2(trip?.return)}
            </Typography>
            <Typography variant="subtitle1" fontSize={13}>
              {trip.organizerCommunity.name}
            </Typography>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Box
                sx={{
                  background: theme.palette.primary.main,
                  color: 'white',
                  textAlign: 'center',
                  py: 0.2,
                  px: 1.2,
                  borderRadius: '24px',
                  fontSize: 12,
                  height: 0.5,
                }}
              >
                {isPaid ? 'Paid' : 'Booked'}
              </Box>
              {!isPaid ? (
                <IconButton color="primary" disabled>
                  <Icon icon="carbon:close-outline" />
                </IconButton>
              ) : (
                <IconButton
                  color="primary"
                  onClick={() => navigate('/receipt/' + tnxRef)}
                >
                  <Icon icon="uil:receipt" />
                </IconButton>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}

export default Ticket;
