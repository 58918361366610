import React, { useEffect } from 'react';
import { AuthHeader } from '../components/AuthHeader';
import UtenticButton from '../../../components/Button/UtenticButton';
import { useState } from 'react';
import OtpInput from 'react-otp-input';
import { useSelector, useDispatch } from 'react-redux';
import { authAction } from '../../../redux/slice/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { Alert } from '@mui/material';

const VerifyCodePage = () => {
  const [code, setCode] = useState('');
  const handleChange = (code) => setCode(code);
  const { isLoading, isError, error, loginSuccess } = useSelector((state) => state.auth);
  console.log({ error });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const phone = new URLSearchParams(location.search).get('phone');
  const next = new URLSearchParams(location.search).get('next');
  const handleOtpCodeSubmit = (otpCode) => {
    dispatch(authAction.INIT_VERIFY_OTP({ otp: otpCode, phoneNumber: phone }));
  };

  useEffect(() => {
    if (loginSuccess) {
      // localStorage.setItem('accessToken', accessToken);
      if (next === 'createCommunity') {
        navigate('/organizer/create-account');
      } else if (next === 'dashboard') {
        navigate('/dashboard');
      } else {
        navigate('/');
      }
    }
  }, [next, navigate, loginSuccess]);
  useEffect(() => {
    if (!phone) navigate('/login');
  }, [phone, navigate]);
  return (
    <>
      <AuthHeader />
      <div className="w-full px-[26px] mx-auto">
        <div className="w-full mt-2 flex flex-col justify-center items-center">
          <div className="w-full sm:w-2/3 md:w-1/3 my-4">
            <p className="text-[24px] font-light">Enter your code</p>
          </div>
          <div className="w-full sm:w-2/3 md:w-1/3 flex flex-col items-center gap-4">
            <div className="w-full">
              <OtpInput
                value={code}
                onChange={handleChange}
                numInputs={4}
                isInputNum={true}
                shouldAutoFocus={true}
                renderInput={(props) => <input {...props} />}
                renderSeparator={
                  <span className="w-[10px] sm:w-[25px] lg:w-[30px]"></span>
                }
                inputStyle={{
                  backgroundColor: '#F5F5F5',
                  border: 'none',
                  borderRadius: '8px',
                  width: '79px',
                  height: '69px',
                  fontSize: '12px',
                  color: '#000',
                  fontWeight: '400',
                  caretColor: 'blue',
                  outline: '0px',
                }}
                focusStyle={{
                  border: '1px solid #CFD3DB',
                  outline: 'none',
                }}
              />
            </div>
            {isError && <Alert variant='outlined' severity="error">{error ? error : 'Failed to process request'}</Alert>}
            <UtenticButton
              disabled={code.length !== 4}
              btnOnclick={() => handleOtpCodeSubmit(code)}
              btnType={'submit'}
              btnText={isLoading ? 'Loading...' : 'Next'}
              btnClassName={'w-full h-[69px] mt-6'}
            />
            <p className="w-full text-[15px] font-normal mt-4">
              Didn't get an SMS?
              <span className="ml-1 text-[#FF5733] underline pb-2">send again</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyCodePage;
