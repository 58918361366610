import React from 'react';
import PropTypes from 'prop-types';

function SliderImage({ image }) {
  SliderImage.propTypes = {
    image: PropTypes.string.isRequired,
  };
  return (
    <div>
      <img src={image} alt="Trip" width={'100%'} height={'450'} />
    </div>
  );
}

export default SliderImage;
