import { Box, Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import LazyImage from '../../components/LazyImage';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import { fDate } from '../../utils';
import Share from '../../components/Share';
const buttonStyle = {
  color: '#00000061',
  background: '#fff',
  border: '1px solid #D9D9D9',
  borderRadius: '11px',
  height: '1.5rem',
};
const buttonProps = {
  disableElevation: true,
  variant: 'contained',
  sx: buttonStyle,
  size: 'small',
};

function TripCard({ trip }) {
  const navigate = useNavigate();
  return (
    // <Box sx={{ background: '#F8F8F8', borderRadius: '5px'}}>
    <Grid container sx={{ background: '#F8F8F8', p: 1, borderRadius: '11px' }}>
      <Grid item xs={3} sx={{ p: 0.5 }}>
        {/* <Box sx={{ m: 1}}> */}
        <LazyImage
          ratio="1/1"
          src={trip.image[Math.floor(Math.random() * trip.image.length)]}
          sx={{ borderRadius: '5px' }}
        />
        {/* </Box> */}
      </Grid>
      <Grid item xs={8} sx={{ pl: 1 }}>
        <Stack spacing={2}>
          <Box>
            <Typography variant="h6" textTransform="capitalize">
              {trip.name}
            </Typography>
            <Typography variant="subtitle2" color="#00000061">
              {fDate(trip.departure)} - {fDate(trip.return)}
            </Typography>
          </Box>
          <Stack direction="row" spacing={0.2}>
            <Box>
              <Button {...buttonProps}>Edit</Button>
            </Box>
            <Box>
              <Button
                {...buttonProps}
                onClick={() => navigate('/booking?tripId=' + trip.id)}
              >
                Bookings
              </Button>
            </Box>
            <Box>
              <Button {...buttonProps}>Close</Button>
            </Box>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={1}>
        <Stack justifyContent="space-between">
          <Box>
            <Share
              link={window.location.origin + '/trip/' + trip.id}
              Comp={
                <IconButton size="large" color="primary">
                  <Icon icon="mdi:share-circle" />
                </IconButton>
              }
            />
          </Box>
          <Box>
            <IconButton size="large" color="primary">
              <Icon icon="ic:outline-delete" color="primary" />
            </IconButton>
          </Box>
        </Stack>
      </Grid>
    </Grid>
    // </Box>
  );
}

export default TripCard;
