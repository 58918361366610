import React, { useEffect } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import { Formik, Form, Field, ErrorMessage } from 'formik';

import { tripAction } from '../../redux/slice/trip';
// import { validationSchema } from './validation';
import { ReactComponent as ChapaIcon } from '../../assets/icon/chapa.svg';
// import ErrorPage from '../ErrorPage';

import './done.css';
import ReactGA from 'react-ga4';

export default function Done() {
  const dispatch = useDispatch();
  const data = useLocation().state;
  const {
    data: trip,
    done,
    checkoutUrl,
    isPaying: isPaying,
    // isLoading: isLoading,
  } = useSelector((state) => state.trips);
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Payment done page',
    });
  }, []);
  const { id } = useParams();
  window.scrollTo(0, 0);
  const navigateTo = useNavigate();

  const contactNumber = '0963742925';
  const bankInfo = [
    { number: 85398587, name: 'Hiwot Medhin', bank: 'BOA' },
    { number: 1000270299877, name: 'Ezana Fitsum', bank: 'CBE' },
  ];
  // const [paymentMethod, setPM] = React.useState('mobile');

  const handleDone = () => {
    if (checkoutUrl) {
      window.location.replace(checkoutUrl);
    } else if (done) {
      navigateTo('/thankyou');
    }
  };

  React.useEffect(() => {
    dispatch(tripAction.LOAD_TRIP(id));
  }, []);

  React.useEffect(() => {
    handleDone();

    return () => {
      dispatch(tripAction.CLEAR());
    };
  }, [done]);

  return (
    <>
      {trip && (
        <div className="container">
          <div className="sub_container">
            <div className="d_header">
              <Link
                onClick={() => {
                  // logBackButton();
                  ReactGA.event({
                    category: 'Back Button',
                    label: 'Back to trip detail page',
                    action: 'click',
                  });
                }}
                to={'/pay/' + trip?.id}
              >
                <div className="header__left icons">
                  <FiArrowLeft />
                </div>
              </Link>
              <a
                className="header__right icons"
                href={'tel:' + contactNumber}
                style={{ color: 'white' }}
                onClick={() => {
                  // logCallButton();
                  ReactGA.event({
                    category: 'Call Button',
                    label: 'call utentic support',
                    action: 'click',
                  });
                }}
              >
                <BsFillTelephoneFill />
              </a>
            </div>

            <div>
              {/* {paymentMethod === 'mobile' ? ( */}
              <div
                className={'tab'}
                onClick={() => {
                  // setPM('chapa');
                  dispatch(
                    tripAction.LOAD_PAYMENT({
                      id: trip?.id,
                      paymentMethod: 'chapa',
                      data: {
                        firstName: data.firstName,
                        phoneNumber: data.phoneNumber,
                        amount: data?.amount,
                        email: 'travel.utentic@gmail.com',
                        lastName: data.lastName,
                      },
                    }),
                  );
                  // logChapaButton();
                  ReactGA.event({
                    category: 'Pay-with-chapa Button',
                    label: 'Change payment to chapa',
                    action: 'click',
                  });
                }}
              >
                <ChapaIcon />
                <h2>Pay with Chapa</h2>
              </div>
              {/* ) : ( */}
              {/* <div
                  className={'tab'}
                  onClick={() => {
                    setPM('mobile');
                    logCallButton();
                    ReactGA.event({
                      category: 'Back done',
                      label: 'Back to done page',
                      action: 'click',
                    });
                  }}
                >
                  <div className="header__left ">
                    <FiArrowLeft />
                  </div>
                  <h2>Mobile payment</h2>
                </div> */}
              {/* )} */}
            </div>

            {/* {paymentMethod === 'mobile' ? ( */}
            <div className="booking">
              <div className="booking_top">
                <p>
                  Keep in mind that you have to pay the amount in order to secure your
                  spot. You can find the payment details down below.
                </p>
              </div>
              <div className="booking_bottom">
                {bankInfo?.map((ac, index) => (
                  <div key={index} className="account">
                    <div key={index} className="account_left">
                      <h4>{ac.number}</h4>
                      <p>{ac.name}</p>
                    </div>
                    <div className="account_right">{ac.bank}</div>
                  </div>
                ))}
              </div>
              <div className="total__amount">
                <p>Total</p>
                <p>{data?.amount} Birr</p>
              </div>
              <div
                className="large_button"
                onClick={() => {
                  // logDoneButton();
                  ReactGA.event({
                    category: 'Done Button',
                    label: 'finish transaction and navigate to thnakyou page',
                    action: 'click',
                  });
                  navigateTo('/thankyou');
                }}
              >
                <button type="submit" disabled={isPaying}>
                  {isPaying ? 'Loading...' : 'Done'}
                </button>
              </div>
            </div>
            {/* ) : ( */}
            {/* <div className="booking">
                <Formik
                  initialValues={{
                    firstName: data.firstName,
                    lastName: '',
                    phoneNumber: data.phoneNumber,
                  }}
                  onSubmit={(values) => {
                    dispatch(
                      tripAction.LOAD_PAYMENT({
                        id: trip?.id,
                        paymentMethod,
                        data: {
                          firstName: values.firstName,
                          phoneNumber: values.phoneNumber,
                          amount: data?.amount,
                          email: 'travel.utentic@gmail.com',
                          lastName: values.lastName,
                        },
                      }),
                    );
                    ReactGA.event({
                      category: 'Pay Button Chapa',
                      label: 'Initialize chapa payment',
                      action: 'submit',
                    });
                  }}
                  validationSchema={validationSchema}
                >
                  {({ _setFieldValue, values, _errors, _touched }) => (
                    <Form className="pay_forms">
                      <div className="input_container">
                        <Field
                          value={values.firstName}
                          name="firstName"
                          type="text"
                          placeholder="First Name"
                          className="input-field"
                        />
                      </div>
                      <ErrorMessage name="firstName" component="div" className="error" />

                      <div className="input_container">
                        <Field
                          value={values.secondName}
                          name="lastName"
                          type="text"
                          placeholder="Last Name"
                          className="input-field"
                        />
                      </div>
                      <ErrorMessage name="lastName" component="div" className="error" />

                      <div className="input_container">
                        <Field
                          value={values.phoneNumber}
                          name="phoneNumber"
                          type="text"
                          placeholder="Phone number"
                          className="input-field"
                        />
                      </div>
                      <ErrorMessage
                        name="phoneNumber"
                        component="div"
                        className="error"
                      />

                      <div className="total__amount">
                        <p>Total</p>
                        <p>{data?.amount} Birr</p>
                      </div>
                      <div className="large_button">
                        <button type="submit" disabled={isPaying}>
                          {isPaying ? 'Loading...' : 'Done'}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div> */}
            {/* )} */}
          </div>
        </div>
      )}
      {/* {!trip && !isLoading && (
        <>
          <ErrorPage />
        </>
      )} */}
    </>
  );
}
