import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import ImageUploading from 'react-images-uploading';
import LazyImage from './LazyImage';

export default function ImageUpload({ images, setImages }) {
  const maxNumber = 15;
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };
  return (
    <div className="App">
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <Box sx={{ py: 2 }}>
              <Stack direction="row" justifyContent="center" alignItems="center">
                <Typography fontSize={16} fontWeight={300} color={'rgba(0, 0, 0, 0.38)'}>
                  Upload trip images
                </Typography>
                <IconButton onClick={onImageUpload}>
                  <Icon icon="ic:twotone-add-a-photo" fontSize={56} />
                </IconButton>
              </Stack>
            </Box>
            {images.length ? (
              <Button
                onClick={onImageRemoveAll}
                startIcon={<Icon icon="clarity:remove-solid" />}
              >
                Clear all
              </Button>
            ) : (
              ''
            )}
            <Grid container spacing={1} sx={{ py: 1 }}>
              {imageList.map((image, index) => (
                <Grid item xs={4} key={index}>
                  <Box sx={{ background: '#ddd', p: 1, m: 0.5, borderRadius: '5px' }}>
                    <LazyImage
                      ratio="1/1"
                      src={image['data_url']}
                      sx={{ borderRadius: '5px', overflow: 'hidden' }}
                    />
                    <Stack justifyContent="space-between" direction="row">
                      <IconButton onClick={() => onImageUpdate(index)}>
                        <Icon icon="radix-icons:update" />
                      </IconButton>
                      <IconButton onClick={() => onImageRemove(index)}>
                        <Icon icon="clarity:remove-solid" />
                      </IconButton>
                    </Stack>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </div>
        )}
      </ImageUploading>
    </div>
  );
}
