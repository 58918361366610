import { call, takeEvery, put } from 'redux-saga/effects';
import { tripAction } from '../slice/trip';
import { AxiosAPI } from '../../config';

function* loadTrip(action) {
  try {
    let response = yield call(() =>
      AxiosAPI({
        url: '/trips/' + action.payload,
        method: 'GET',
      }),
    );
    if (response.status === 200) {
      yield put(tripAction.LOAD_TRIP_SUCCESS(response.data));
    }
  } catch (e) {
    yield put(tripAction.LOAD_TRIP_ERROR());
    yield console.log('Error ---> ', e);
  }
}

function* loadPayment(action) {
  try {
    const { paymentMethod, id, data } = action.payload;
    let response =
      paymentMethod === 'mobile'
        ? yield call(() =>
            AxiosAPI({ url: '/users/book-trip/' + id, method: 'POST', data }),
          )
        : yield call(() => AxiosAPI({ url: '/chapa/pay/' + id, method: 'POST', data }));

    if (response.status === 200) {
      const done = response.data.success || response.data.status === 'success';
      const checkoutUrl = response.data.data.checkout_url || '';

      yield put(
        tripAction.LOAD_PAYMENT_SUCCESS({
          done,
          checkoutUrl,
        }),
      );
    }
  } catch (e) {
    yield put(tripAction.LOAD_PAYMENT_ERROR());
    yield console.log('Error ---> ', e);
  }
}

export default function* tripSaga() {
  yield takeEvery(tripAction.LOAD_TRIP.type, loadTrip);
  yield takeEvery(tripAction.LOAD_PAYMENT.type, loadPayment);
}
