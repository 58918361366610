import React, { useEffect } from 'react';

import { ReactComponent as UtenticLogo } from '../../assets/icon/utentic-logo.svg';
import './home.css';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Fab,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import Header from './Header';
import { Icon } from '@iconify/react';
import HorizontalCards from './HorizontalCards';
import { useState } from 'react';
import { AxiosAPI } from '../../config';
import TripCard from './TripCard';
import { fDate } from '../../utils';

export default function Home() {
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [trips, setTrips] = useState(null);
  const theme = useTheme();
  const [search, setSearch] = useState('');
  const [showMore, setShowMore] = useState(false);
  const [showMoreList, setShowMoreList] = useState([]);

  const handleSetFilter = (item) => {
    if (selectedFilter === item) {
      setSelectedFilter('');
    } else {
      setSelectedFilter(item);
    }
  };
  const handleFilterDate = (start, end) => {
    fetchTrips({ start, end });
  };
  const fetchTrips = (filterData = undefined) => {
    setTrips(null);
    AxiosAPI({
      url: '/trips/browse',
      method: 'POST',
      data: {
        filter: !selectedFilter ? '' : selectedFilter.split(' ')[1].toLowerCase(),
        filterData,
      },
    })
      .then((d) => d.data.data)
      .then(setTrips);
  };
  const handleClearSearch = () => {
    setSelectedFilter('');
    setSearch('');
  };
  useEffect(() => {
    fetchTrips();
  }, []);
  useEffect(() => {
    if (selectedFilter != null && !selectedFilter.includes('Date')) {
      fetchTrips(search);
    }
  }, [selectedFilter]);
  const handleSubmit = (event) => {
    if (event.key === 'Enter') {
      setSelectedFilter('by search');
    }
  };
  const handleCancelShowMore = () => {
    setShowMore('');
    setShowMoreList([]);
  };
  const handleShowMore = (name, value) => {
    setShowMore(name);
    setShowMoreList(value);
  };
  return (
    <Container maxWidth="sm" sx={{ p: 0 }}>
      <Header />
      <Stack spacing={2} sx={{ px: 2 }}>
        <Stack sx={{ pt: 3 }}>
          <Typography variant="subtitle2">Discover</Typography>
          <Typography variant="h5" sx={{ fontWeight: 'bold' }} textTransform="capitalize">
            {showMore ? showMore : "What's Out There"}
          </Typography>
        </Stack>
        {!showMore && (
          <>
            <TextField
              size="small"
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon icon="iconamoon:search-bold" color="black" size={30} />
                  </InputAdornment>
                ),
              }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              color="secondary"
              name="search"
              type="search"
              sx={{
                background: '#F5F5F5',
                borderRadius: '8px',
                '& fieldset': { border: 'none' },
              }}
              onKeyDown={handleSubmit}
            />
            {search && <Button onClick={handleClearSearch}>Clear search</Button>}
          </>
        )}
      </Stack>
      {showMore ? (
        <Stack sx={{ px: 2 }}>
          <Button
            sx={{ py: 2 }}
            startIcon={<Icon icon="pajamas:go-back" />}
            onClick={handleCancelShowMore}
          >
            Go Back
          </Button>
          <Stack>
            {showMoreList.map((trip) => (
              <TripCard trip={trip} key={trip.id} />
            ))}
          </Stack>
        </Stack>
      ) : (
        <>
          <Stack direction="row" sx={{ py: 3, px: 2, justifyContent: 'space-between' }}>
            {['By Date', 'By Community', 'By Destination', 'By Category'].map(
              (item, key) => {
                const on = selectedFilter === item;
                return (
                  <Chip
                    key={key}
                    label={item}
                    variant={on ? 'filled' : 'outlined'}
                    size="small"
                    clickable={true}
                    onClick={() => handleSetFilter(item)}
                    sx={{
                      borderRadius: '4px',
                      color: on ? 'white' : '#00000061',
                      borderColor: ' #D9D9D9',
                      background: on ? theme.palette.primary.main : undefined,
                      '&:focus': {
                        background: theme.palette.primary.main,
                      },
                      '&:active': {
                        background: theme.palette.primary.main,
                        boxShadow: 'none',
                      },
                    }}
                  />
                );
              },
            )}
          </Stack>
          {selectedFilter === 'By Date' && (
            <DateSelector handleFilterDate={handleFilterDate} />
          )}
          {trips ? (
            trips.groups.length + trips.this_week.length + trips.other.length === 0 ? (
              <h2>Sorry we have no trips. Come back later?</h2>
            ) : (
              <>
                {trips.this_week.length > 0 && (
                  <HorizontalCards
                    title="This week"
                    trips={trips.this_week}
                    handleShowMore={handleShowMore}
                  />
                )}
                {trips.groups.length > 0 &&
                  trips.groups.map(({ name, value }, key) => (
                    <HorizontalCards
                      title={name}
                      trips={value}
                      key={key}
                      handleShowMore={handleShowMore}
                    />
                  ))}
                {trips.other.length > 0 &&
                  trips.other.map((trip) => <TripCard trip={trip} key={trip.id} />)}
              </>
            )
          ) : (
            <Box sx={{ display: 'flex', py: 10, justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          )}
        </>
      )}
    </Container>
  );
}
function DateSelector({ handleFilterDate }) {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [done, setDone] = useState(false);
  const handleClick = () => {
    if (startDate && endDate) {
      handleFilterDate(startDate, endDate);
      setDone(true);
    }
  };
  return (
    <Stack sx={{ p: 1 }}>
      <Grid container spacing={4}>
        <Grid item xs={5}>
          <TextField
            type="date"
            size="small"
            sx={{ background: '#F5F5F5', borderRadius: '8px' }}
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputProps={{ inputProps: { max: endDate } }}
            label="Start Date"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            type="date"
            size="small"
            sx={{ background: '#F5F5F5', borderRadius: '8px' }}
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputProps={{ inputProps: { min: startDate } }}
            label="End Date"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <Fab color="primary" size="small" onClick={handleClick}>
            <Icon icon="teenyicons:arrow-right-solid" size={40} />
          </Fab>
        </Grid>
      </Grid>
      {done && startDate && endDate && (
        <Typography variant="h6" textTransform="capitalize" sx={{ pt: 3 }}>
          {fDate(startDate)} - {fDate(endDate)}
        </Typography>
      )}
    </Stack>
  );
}
