import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
  done: false,
  checkoutUrl: '',
  ticket: 1,
  isLoading: false,
  isPaying: false,
  isError: false,
};

export const tripSlice = createSlice({
  name: 'trips',
  initialState,
  reducers: {
    LOAD_TRIP: (state, _action) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    LOAD_TRIP_SUCCESS: (state, action) => {
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
      };
    },
    LOAD_TRIP_ERROR: (state, _action) => {
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    },
    LOAD_PAYMENT: (state, _action) => {
      return {
        ...state,
        done: false,
        checkoutUrl: '',
        isPaying: true,
      };
    },
    LOAD_PAYMENT_SUCCESS: (state, action) => {
      return {
        ...state,
        done: action.payload.done,
        checkoutUrl: action.payload.checkoutUrl,
        isPaying: false,
      };
    },
    LOAD_PAYMENT_ERROR: (state, _action) => {
      return {
        ...state,
        isPaying: false,
        isError: true,
      };
    },

    CLEAR: (_state, _action) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { actions: tripAction, name: tripSliceName } = tripSlice;
export default tripSlice.reducer;
