import React, { useEffect, useState } from 'react';
import { Close, CheckCircle } from '@mui/icons-material';
import { ReactComponent as UtenticLogo } from '../../assets/icon/utentic-logo.svg';
import { BsFillTelephoneFill } from 'react-icons/bs';

import './style.css';
import { AxiosAPI } from '../../config';
import { Box, CircularProgress } from '@mui/material';
import { fDate3 } from '../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function ReceiptPage() {
  const { receipt } = useParams();
  const [tnx, setTnx] = useState(null);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Receipt page',
    });
  }, []);
  useEffect(() => {
    if (receipt) {
      AxiosAPI({
        url: '/trips/receipt/' + receipt,
        method: 'GET',
      })
        .then((d) => {
          if (d?.data?.success) {
            return d.data.data;
          }
          throw Error('Invalid receipt');
        })
        .then((d) => setTnx(d))
        .catch(() => setError(true));
    }
  }, [receipt]);
  if (error) return <h1>Invalid receipt</h1>;
  if (!tnx)
    return (
      <Box sx={{ display: 'flex', py: 10, justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  return (
    <div className="reciept-container">
      <div className="receipt-header">
        <Close
          sx={{
            color: '#FF5733',
          }}
          onClick={() => navigate('/tickets')}
        />
        <UtenticLogo className="receipt-logo" onClick={() => navigate('/')} />
      </div>
      <div className="receipt-success">
        <div className="receipt-success-icon">
          <CheckCircle
            sx={{
              color: '#FF5733',
              fontSize: '2em',
            }}
          />
        </div>
        <div className="receipt-success-header">Payment Success!</div>
        <div className="receipt-success-text">
          Your payment has been successfully done.
        </div>
      </div>
      <div className="payment-details">
        <h4>Payment Details</h4>
        <div className="inner-payment-details">
          <div className="inner-payment-txt">
            <div className="inner-payment-txt-left">Ref Number</div>
            <div className="inner-payment-txt-right">{tnx.tnxRef}</div>
          </div>
          <div className="inner-payment-txt">
            <div className="inner-payment-txt-left">Payment Time</div>
            <div className="inner-payment-txt-right">{fDate3(tnx.createdDate)}</div>
          </div>
          <div className="inner-payment-txt">
            <div className="inner-payment-txt-left">Payment Method</div>
            <div className="inner-payment-txt-right">{tnx.paymentMethod}</div>
          </div>
          <div className="inner-payment-txt">
            <div className="inner-payment-txt-left">Organizer</div>
            <div className="inner-payment-txt-right">
              {tnx.trip.organizerCommunity.name}
            </div>
          </div>
          <div className="inner-payment-txt">
            <div className="inner-payment-txt-left">Sender Name</div>
            <div className="inner-payment-txt-right">{tnx.user.fullName}</div>
          </div>
          <div className="inner-payment-txt">
            <div className="inner-payment-txt-left">Number of people</div>
            <div className="inner-payment-txt-right">{tnx.amountOfPeople}</div>
          </div>
          <div className="inner-payment-divider"></div>
          <div className="inner-payment-txt">
            <div className="inner-payment-txt-left">Discount</div>
            <div className="inner-payment-txt-right">
              {tnx.trip.discountAmount * tnx.amountOfPeople} Birr
            </div>
          </div>
          <div className="inner-payment-txt">
            <div
              style={{
                color: 'rgba(255, 87, 51, 1)',
                fontSize: '13px',
                fontWeight: '600',
              }}
            >
              Total Paid
            </div>
            <div
              style={{
                color: 'rgba(255, 87, 51, 1)',
                fontSize: '13px',
                fontWeight: '600',
              }}
            >
              {tnx.amount ||
                tnx.trip.price * tnx.amountOfPeople -
                  tnx.trip.discountAmount * tnx.amountOfPeople}{' '}
              Birr
            </div>
          </div>
          <div className="inner-payment-txt">
            <div className="inner-payment-txt-left">Payment Status</div>
            <div className="inner-payment-badge">Success</div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="thanks-container">
        <div className="thanks-text">
          Thank you for booking. We will contact you soon. Call us if you have any
          questions.
        </div>
        <a
          className="thanks-icon"
          href={`tel:${tnx.trip.organizerCommunity.organizer.phoneNumber}`}
          style={{ color: 'white' }}
        >
          <BsFillTelephoneFill size={20} />
        </a>
      </div>
    </div>
  );
}
