import React, { useEffect } from 'react';
import { AuthHeader } from '../components/AuthHeader';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import UtenticButton from '../../../components/Button/UtenticButton';
import { useNavigate } from 'react-router-dom';
import { loginValidationSchema } from './validation';
import { authAction } from '../../../redux/slice/auth';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material';
import ReactGA from 'react-ga4';
const OrganizerLoginPage = () => {
  const navigate = useNavigate();
  const { user, isLoading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const theme = useTheme();
  const handleSubmit = (values) => {
    dispatch(authAction.INIT_LOGIN(values));
  };
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Organizer auth login page',
    });
  }, []);
  useEffect(() => {
    if (user) {
      navigate('/verify?next=dashboard&phone=' + user.phoneNumber);
    }
  }, [user]);
  return (
    <>
      <AuthHeader />
      <div className="w-full px-[26px] mx-auto">
        <div className="w-full mt-2 flex flex-col justify-center items-center">
          <div className="w-full sm:w-2/3 md:w-1/3 my-4">
            <p className="text-[24px] font-light">Login to your Organizer account</p>
          </div>
          <Formik
            initialValues={{}}
            onSubmit={(values) => {
              handleSubmit({ ...values, phoneNumber: `0${values.phoneNumber}` });
            }}
            validationSchema={loginValidationSchema}
          >
            {({ values }) => (
              <Form className="w-full sm:w-2/3  md:w-1/3 h-full flex flex-col items-between justify-between gap-4">
                <div className="flex relative bg-gray-100">
                  <UtenticButton
                    btnType="button"
                    btnText={'+251'}
                    btnClassName={'w-[85px] h-[69px]'}
                  />
                  <Field
                    value={values.firstName}
                    name="phoneNumber"
                    type="number"
                    pattern="[0-9]+"
                    placeholder="Phone Number"
                    className="w-full bg-[#F5F5F5] border-none hover:border-none px-5 h-[69px] outline-0"
                  />
                </div>
                <ErrorMessage name="phoneNumber" component="div" className="error" />
                <a
                  href="/organizer/signup"
                  style={{
                    color: theme.palette.primary.main,
                    textDecoration: 'underline',
                  }}
                >
                  Don't have an account?
                </a>
                <UtenticButton
                  btnType={'submit'}
                  btnText={isLoading ? 'Loading...' : 'Go'}
                  btnClassName={'w-full h-[69px] mt-[100px]'}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default OrganizerLoginPage;
