import React, { useEffect } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { TelegramShareButton, TelegramIcon } from 'react-share';
import ReactGA from 'react-ga';

import { ReactComponent as UtenticLogo } from '../../assets/icon/utentic-logo.svg';
import { logoStyle, btnStyle, shareStyle } from './styles';
import { tripAction } from '../../redux/slice/trip';
import SliderImage from '../../components/SliderImage';
import ErrorPage from '../ErrorPage';
import './style.css';
import { Chip, Fab, useTheme } from '@mui/material';
import { Icon } from '@iconify/react';
import Share from '../../components/Share';

const ExperienceDetail = () => {
  const currentUrl = window.location.href;
  const experience = currentUrl.split('/')[4];
  const dispatch = useDispatch();
  const theme = useTheme();
  useEffect(() => {
    dispatch(tripAction.LOAD_TRIP(experience));
  }, []);

  const trip = useSelector((state) => state.trips?.data);
  const isLoading = useSelector((state) => state.trips?.isLoading);
  const images = trip?.image;

  const url = window.location.origin + '/trip/' + trip?.id;
  const company_logo = trip?.organizerCommunity?.image;

  const date = new Date(trip?.departure || new Date());
  const options = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  const formatter = new Intl.DateTimeFormat('en-US', options);
  const depTime = formatter.format(date);
  const options1 = { weekday: 'short', month: 'short', day: 'numeric' };
  const depDate = new Date(trip?.departure || new Date()).toLocaleDateString(
    'en-US',
    options1,
  );
  const retDate = new Date(trip?.return || new Date()).toLocaleDateString(
    'en-US',
    options1,
  );
  console.log(trip);
  return (
    <div className="mainf">
      {trip && (
        <>
          {images?.length > 0 ? (
            <>
              <AliceCarousel
                mouseTrackingEnabled
                disableButtonsControls
                autoPlay={true}
                infinite={true}
                animationDuration={1000}
                autoPlayInterval={2000}
              >
                {images?.map((image, idx) => (
                  <SliderImage key={idx} image={image} />
                ))}
              </AliceCarousel>
              <Link to="/">
                <div style={{ ...logoStyle }}>
                  <UtenticLogo />
                </div>
              </Link>
              <div style={shareStyle}>
                <Share
                  link={url}
                  Comp={
                    <Fab
                      color="primary"
                      size="small"
                      elevation={0}
                      onClick={() => {
                        // logTelegramShare();
                        ReactGA.event({
                          category: 'Share Button',
                          label: 'share sheet opened',
                          action: 'click',
                        });
                      }}
                    >
                      <Icon icon="mdi:share" fontSize={25} />
                    </Fab>
                  }
                />
              </div>
              <a
                style={btnStyle}
                // href={'tel:' + }
              >
                <BsFillTelephoneFill color="#fff" />
              </a>
            </>
          ) : (
            <div className="noimage">
              <h3>No Image For the Trip</h3>
            </div>
          )}
          <div className="ucontainer">
            <div className="title-date">
              <h5 className="title">{experience.name}</h5>
            </div>
            
            <div className="description">
              {experience.description && <p
                className="description-text"
                dangerouslySetInnerHTML={{ __html: experience.description }}
              ></p>}
            </div>
            <div className="icons-group">
              <div className="title">
                <p className="title-text">Package Includes</p>
                <div className="list">
                  {experience.packageIncluded.map((el, index) => (
                    <span key={index} className="btn_small">
                      <Chip
                        label={el.tripPackageIncluded.name}
                        icon={
                          <Icon
                            icon="carbon:checkmark-filled"
                            color={theme.palette.primary.main}
                          />
                        }
                        sx={{ background: 'white' }}
                      />
                    </span>
                  ))}
                </div>
              </div>
              <div></div>
            </div>
            <div className="icons-group">
              <div className="title">
                <p className="title-text">Activities</p>
                <div className="list">
                  {experience.activities.map((el, index) => (
                    <span key={index} className="btn_small">
                      <Chip
                        label={el.tripActivities.name}
                        icon={
                          <Icon
                            icon="carbon:checkmark-filled"
                            color={theme.palette.primary.main}
                          />
                        }
                        sx={{ background: 'white' }}
                      />
                    </span>
                  ))}
                </div>
              </div>
              <div></div>
            </div>
          </div>
          <div className="buffer" />
          <div className="book">
            <Link
              onClick={() => {
                ReactGA.event({
                  category: 'Book Button',
                  label: 'start booking trip',
                  action: 'click',
                });
              }}
              to={`/pay/${experience.id}`}
              className="book-btn"
            >
              Customize
            </Link>
          </div>
        </>
      )}{' '}
      {!experience && !isLoading && (
        <>
          <ErrorPage />
        </>
      )}
    </div>
  );
};

export default ExperienceDetail;

