import * as Yup from 'yup';

export const loginValidationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .trim()
    .matches(/^(9|7)\d{8}$/, 'Phone number must be in the format +251#########')
    .required('PhoneNumber is required'),
});

export const signupValidationSchema = Yup.object().shape({
  fullName: Yup.string()
    .trim()
    .min(3, 'Full Name must be at least 5 characters')
    .max(50, 'Full Name must be less than 50 characters')
    .required('Full Name is required'),
  phoneNumber: Yup.string()
    .trim()
    .matches(/^(9|7)\d{8}$/, 'Phone number must be in the format +251#########')
    .required('PhoneNumber is required'),
  age: Yup.string()
    .trim()
    .min(2, 'age must be at least 10')
    .max(3, 'Age must be less than 100')
    .required('Age is required'),
  gender: Yup.string()
    .trim()
    .min(3, 'Gender must be at least 3 characters')
    .max(20, 'Gender must be less than 20 characters')
    .required('Gender is required'),
});

export const createCommunityValidationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(3, 'Name must be at least 5 characters')
    .max(50, 'Name must be less than 50 characters')
    .required('Name is required'),
  bankCode: Yup.string().trim().required('Bank name is required'),
  accountNumber: Yup.string().trim().required('Account number  is required'),
  file: Yup.mixed().required('File is required'),
});

export const createTripValidation = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(3, 'Name must be at least 5 characters')
    .max(50, 'Name must be less than 50 characters')
    .required('Name is required'),
  departure: Yup.date()
    .min(new Date(), 'Date must be in the future')
    .required('Departure Date is required'),
  return: Yup.date()
    .min(new Date(), 'Date must be in the future')
    .required('Departure Date is required'),
  description: Yup.string().trim().required('Description is required'),
  price: Yup.number()
    .min(0, 'Price must be greater than zero')
    .required('Price is required'),
  meetUpLocation: Yup.string().trim().required('Meet Up Location is required'),
  categoryId: Yup.string().trim().required('Category is required'),
});
