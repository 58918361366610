import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';

// import { ReactComponent as UtenticLogo } from '../../assets/icon/utentic-logo.svg';
// import './home.css';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Fab,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import ExperienceCard from '../Home/ExperienceCard';


export default function Experience() {
  const [trips, setTrips] = useState(null);
  const fetchTrips = () => {
    setTrips(null);
    // AxiosAPI({
    //   url: '/experiences',
    //   method: 'GET',
    //   // data: {
    //   //   filter: !selectedFilter ? '' : selectedFilter.split(' ')[1].toLowerCase(),
    //   //   filterData,
    //   // },
    // })
    //   .then((d) => d.data.data)
    //   .then(setTrips);
  };

  useEffect(() => {
    fetchTrips();
  }, []);

  return (
    <Container maxWidth="sm" sx={{ p: 0 }}>
      {/* <Header /> */}
      <Stack spacing={2} sx={{ px: 2 }}>
        <Stack sx={{ pt: 3 }}>
          <Typography variant="subtitle2">Discover</Typography>
          <Typography variant="h5" sx={{ fontWeight: 'bold' }} textTransform="capitalize">
            What's Out There
          </Typography>
          <Typography variant='body2'>Explore our customizable Packages</Typography>
        </Stack>

      </Stack>
      {trips ?
        trips.map((trip) => (
          <ExperienceCard trip={trip} key={trip.id} />
        )) : (
          <Box sx={{ display: 'flex', py: 10, justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        )}
    </Container>
  );
}