import React, { useEffect } from 'react';

import Header from './../../components/Header';
import TripForm from '../../components/TripForm';
import './style.css';
import ReactGA from 'react-ga4';

export default function TripCreatePage() {
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Organizer auth signup page',
    });
  }, []);
  return (
    <div className="create-page">
      <Header />
      <div className="form-header">Create Your trip!</div>
      <TripForm />
    </div>
  );
}
