import React from 'react';
import logo from '../../../assets/img/utentic_logo.jpg';
export const AuthHeader = () => {
  return (
    <div className="w-full relative top-0 left-0 px-2 py-5 flex items-center justify-between">
      <a href="/">
        <div className="">
          <img className="cursor-pointer w-[60px] h-[60px]" src={logo} alt="logo" />
        </div>
      </a>
      <div className="mr-[20px]">
        <p className="text-[#FF5733] text-[16px] font-semibold cursor-pointer">Utentic</p>
      </div>
    </div>
  );
};
