import React, { useEffect } from 'react';
import './thankyou.css';
import Modal from '../../components/Modal/modal';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function Thankyou() {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Thank you page',
    });
  }, []);
  useEffect(() => {
    const receipt = new URLSearchParams(location.search).get('receipt');
    // const paylater = new URLSearchParams(location.search).get('paylater');
    if (receipt) {
      navigate('/receipt/' + receipt);
    }
    // if(paylater) {
    //   navigate('/receipt/' + receipt);
    // }
  }, []);
  return (
    <div className="container">
      <Modal />
    </div>
  );
}
