import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Fab,
  IconButton,
  Snackbar,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import LazyImage from '../../components/LazyImage';
import { Icon } from '@iconify/react';
import { fCurrency, fDate } from '../../utils';
import { useNavigate } from 'react-router-dom';
import Share from '../../components/Share';

function TripCard({ trip }) {
  const { name, image, departure, organizerCommunity } = trip;
  const theme = useTheme();
  const imageUrl = useMemo(
    () => image[Math.floor(image.length * Math.random())],
    [image],
  );

  const navigate = useNavigate();

  return (
    <Stack sx={{ minWidth: '90vw', mx: 1, pb: 2, mt: 2 }}>
      <Card
        sx={{ borderRadius: '20px', position: 'relative' }}
        elevation={5}
        onClick={() => navigate('/trip/' + trip.id)}
      >
        <LazyImage src={imageUrl} ratio="1/1" alt="trip card" />
        <Stack
          sx={{
            position: 'absolute',
            top: 0,
            zIndex: 1,
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            width: '100%',
            background:
              'linear-gradient(to bottom, rgba(0,0,0,0),rgba(0,0,0,0), rgba(0,0,0,0.9));',
          }}
        >
          <Box>
            {trip.discounted && (
              <Chip
                variant="filled"
                label={`${Math.ceil(
                  ((trip.discountAmount || 0) * 100) / trip.price,
                )}% OFF`}
                sx={{
                  color: theme.palette.primary.main,
                  background: 'white',
                  fontSize: 20,
                  fontWeight: '700',
                  borderRadius: '9px',
                  zIndex: 1,
                }}
              />
            )}
          </Box>
          <Stack>
            <Typography
              variant="h5"
              color="white"
              fontWeight="700"
              textTransform="capitalize"
            >
              {name}
            </Typography>
            <Typography variant="subtitle2" color="white">
              {fDate(departure)}
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <Avatar sx={{ border: '1px solid white' }}>
                <LazyImage src={organizerCommunity.image} ratio="1/1" />
              </Avatar>
              <Typography variant="subtitle2" fontWeight="300" color="white">
                {organizerCommunity.name}
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Share
                link={window.location.origin + '/trip/' + trip.id}
                Comp={
                  <Fab color="primary" size="medium">
                    <Icon icon="mdi:share" />
                  </Fab>
                }
              />
            </Stack>
          </Stack>
        </Stack>
      </Card>
      <Stack justifyContent="space-between" sx={{ pt: 2, px: 2 }} direction="row">
        <Stack>
          {trip.discounted && (
            <Typography
              variant="body2"
              color="primary"
              fontWeight={600}
              sx={{ textDecoration: 'line-through', height: '1.2rem' }}
              fontSize={18}
            >
              {fCurrency(trip.price)}
            </Typography>
          )}
          <Typography variant="body1" fontWeight={700} fontSize={32}>
            {fCurrency(trip.price - (trip.discountAmount || 0))}
          </Typography>
        </Stack>
        <Box sx={{ display: 'flex' }}>
          <Button
            variant="contained"
            sx={{ alignSelf: 'flex-end', borderRadius: '21px', mb: 1 }}
            disableElevation
            size="large"
            onClick={() => navigate('/trip/' + trip.id)}
          >
            Book now
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
}

export default TripCard;
