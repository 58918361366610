import { Icon } from '@iconify/react';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import TripCard from './TripCard';

function HorizontalCards({ title, trips, handleShowMore }) {
  return (
    <Box>
      <Stack sx={{ px: 2 }} direction="row" justifyContent="space-between">
        <Typography variant="h6" textTransform="capitalize">
          {title}
        </Typography>
        <Button
          size="small"
          variant="text"
          color="secondary"
          onClick={() => handleShowMore(title, trips)}
        >
          See more
          <Icon icon="teenyicons:arrow-right-solid" />
        </Button>
      </Stack>
      <Box style={{ overflowX: 'scroll', display: 'flex', whiteSpace: 'nowrap', pb: 1 }}>
        {trips.map((trip) => (
          <TripCard trip={trip} key={trip.id} />
        ))}
      </Box>
    </Box>
  );
}

export default HorizontalCards;
