import React, { useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useDispatch } from 'react-redux';
// import ReactGA from 'react-ga';

import { validationSchema } from './validation';
import { tripAction } from '../../redux/slice/trip';
import ErrorPage from '../ErrorPage';

import './pay.css';
import { Button, Card, CircularProgress } from '@mui/material';
import { baseURL } from '../../config';
import { AuthHeader } from '../auth/components/AuthHeader';
import ReactGA from 'react-ga4';

function Pay() {
  const { data: trip, isPaying, isLoading, done } = useSelector((state) => state.trips);
  const { isLoading: waitingForUser } = useSelector((state) => state.auth);
  window.scrollTo(0, 0);
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Payment page',
    });
  }, []);
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const getAmount = (ticket) => {
    if (trip?.discounted) {
      if (ticket >= trip?.discountPeople) {
        return trip?.price * ticket - trip?.discountAmount * ticket;
      }
    }
    return trip?.price * ticket;
  };

  const paymentMethod = 'mobile';
  const [vals, setVals] = React.useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    ticket: 1,
  });
  React.useEffect(() => {
    dispatch(tripAction.LOAD_TRIP(id));
  }, []);

  React.useEffect(() => {
    if (done) {
      navigateTo('/done/' + trip?.id, {
        state: { ...vals, amount: getAmount(vals.ticket) },
      });
    }

    return () => {
      dispatch(tripAction.CLEAR());
    };
  }, [done]);

  if (waitingForUser)
    return (
      <div>
        <AuthHeader />
        <center>
          <CircularProgress />
        </center>
      </div>
    );
  return (
    <>
      {trip && (
        <div className="container">
          <div className="sub_container">
            <div className="p_header" style={{ paddingTop: '1rem' }}>
              <Link
                onClick={() => {
                  // logBackButton();
                  ReactGA.event({
                    category: 'Back Button',
                    label: 'Back to trip detail page',
                    action: 'click',
                  });
                }}
                to={{ pathname: '/trip/' + trip?.id }}
              >
                <div className="header__left icons">
                  <FiArrowLeft />
                </div>
              </Link>
              <a
                className="header__right icons"
                href={'tel:' + trip.organizerCommunity.organizer.phoneNumber}
                style={{ color: 'white' }}
                onClick={() => {
                  // logCallButton();
                  ReactGA.event({
                    category: 'Call Button',
                    label: 'call utentic support',
                    action: 'click',
                  });
                }}
              >
                <BsFillTelephoneFill />
              </a>
            </div>
            <Formik
              initialValues={{
                firstName: '',
                lastName: '',
                phoneNumber: '',
                ticket: 1,
              }}
              onSubmit={(values) => {
                // dispatch(
                //   tripAction.LOAD_PAYMENT({
                //     id: trip?.id,
                //     paymentMethod,
                //     data: {
                //       firstName: values.firstName,
                //       lastName: values.lastName,
                //       phoneNumber: values.phoneNumber,
                //       ticket: values.ticket,
                //     },
                //   }),
                // );
                // logPayButton();
                ReactGA.event({
                  category: 'Pay Button',
                  label: 'Trip booked',
                  action: 'submit',
                });
                setVals({ ...values });
              }}
              validationSchema={validationSchema}
            >
              {({ setFieldValue, values, _errors, _touched }) => (
                <Form className="pay_forms">
                  <div className="pay_booking">
                    <div className="booking__left">
                      <p>How many people are you booking for?</p>
                    </div>
                    <div className="booking_right">
                      <div
                        className="count_icon"
                        onClick={() => {
                          setFieldValue(
                            'ticket',
                            values.ticket <= 1 ? 1 : values.ticket - 1,
                          );
                          // logMinusButton();
                          ReactGA.event({
                            category: 'Minus Button',
                            label: 'decrease number of tickets',
                            action: 'click',
                            value: vals.ticket,
                          });
                        }}
                      >
                        -
                      </div>
                      <Field
                        name="ticket"
                        type="number"
                        className="minified"
                        value={values.ticket}
                      />
                      <div
                        className="count_icon"
                        onClick={() => {
                          setFieldValue('ticket', values.ticket + 1);
                          // logPlusButton();
                          ReactGA.event({
                            category: 'Plus Button',
                            label: 'increase number of tickets',
                            action: 'click',
                            value: vals.ticket,
                          });
                        }}
                      >
                        +
                      </div>
                    </div>
                  </div>
                  <ErrorMessage name="ticket" component="div" className="error" />

                  <div className="with_icon input_container">
                    <div className="tag">+251</div>
                    <div className="input">
                      <Field
                        value={values.phoneNumber}
                        name="phoneNumber"
                        type="text"
                        placeholder="Emergency Number"
                        className="input-field"
                      />
                    </div>
                  </div>
                  <ErrorMessage name="phoneNumber" component="div" className="error" />
                  <Card sx={{ p: 2, borderRadius: '10px', boxShadow: 3 }} elevation={10}>
                    <div className="total">
                      <div className="discount">
                        <p style={{ color: '#707070', fontSize: '14px' }}>
                          Number of people
                        </p>
                        <p>{values.ticket}</p>
                      </div>
                      {trip?.discounted && (
                        <div className="discount">
                          <p style={{ color: '#707070', fontSize: '14px' }}>Discount</p>
                          <p className="amount">
                            {trip?.price * values.ticket} Birr
                          </p>
                        </div>
                      )}
                      <div className="total__amount">
                        <p>Total</p>
                        <p>{getAmount(values.ticket)} Birr</p>
                      </div>
                    </div>
                  </Card>
                  <form
                    method="post"
                    action={`${baseURL}/chapa/pay/${trip.id}?token=${localStorage.getItem(
                      'accessToken',
                    )}`}
                  >
                    <div style={{ display: 'none' }}>
                      <input name="emergencyNumber" defaultValue={values.phoneNumber} />
                      <input name="amountOfPeople" defaultValue={values.ticket} />
                    </div>
                    <div className="large_button">
                      <button type="submit" disabled={isPaying}>
                        {isPaying ? 'Loading...' : 'Pay'}
                      </button>
                    </div>
                  </form>
                  <form
                    method="post"
                    action={`${baseURL}/chapa/payLater/${trip.id
                      }?token=${localStorage.getItem('accessToken')}`}
                  >
                    <div style={{ display: 'none' }}>
                      <input name="emergencyNumber" defaultValue={values.phoneNumber} />
                      <input name="amountOfPeople" defaultValue={values.ticket} />
                    </div>
                    <Button type="submit" fullWidth>
                      Pay later
                    </Button>
                  </form>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}{' '}
      {!trip && !isLoading && (
        <>
          <ErrorPage />
        </>
      )}
    </>
  );
}

export default Pay;
