import React, { useEffect, useState } from 'react';
import { AuthHeader } from '../components/AuthHeader';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import UtenticButton from '../../../components/Button/UtenticButton';
import { useNavigate } from 'react-router-dom';
import { createCommunityValidationSchema, signupValidationSchema } from './validation';
import cameraImg from '../../../assets/icon/camera.png';
import { AxiosAPI } from '../../../config';
import { Alert } from '@mui/material';
import ReactGA from 'react-ga4';

const initialValues = {
  name: '',
  bankCode: '',
  accountNumber: '',
  file: null,
};
const CreateCommunityAccountPage = () => {
  const navigate = useNavigate();
  const imageElRef = React.createRef(null);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [banks, setBanks] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Organizer auth signup page',
    });
    AxiosAPI({
      url: '/chapa/bankCodes',
      method: 'GET',
    })
      .then((d) => d?.data?.data?.data)
      .then((d) => setBanks(d || []));
  }, []);
  const handleSubmit = ({ name, bankCode, accountNumber, file }, { setFieldError }) => {
    const acc_length = banks.find((b) => b.id === bankCode).acct_length;
    if (accountNumber.length !== acc_length) {
      setFieldError(
        'accountNumber',
        `Identifier should be ${acc_length} but provided ${accountNumber.length}`,
      );
      return;
    }
    setError(null);
    let formData = new FormData();
    formData.append('name', name);
    formData.append('bankCode', bankCode);
    formData.append('accountNumber', accountNumber);
    formData.append('file', file);
    setLoading(true);
    AxiosAPI({
      url: '/communities',
      method: 'POST',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((e) => {
        if (e.status == 200 || e.response.status == 200) {
          navigate('/dashboard');
        }
        console.log(e);
        throw Error(e.response.data.message || e.response.data.message);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => setLoading(false));
  };
  const handleFileUpload = (event) => {
    let reader = new FileReader();
    let file = event.target.files[0];
    reader.onloadend = () => {
      setFile(reader.result);
    };
    reader.readAsDataURL(file);
  };
  const placeholderText = (id) => {
    const bank = banks.find((b) => b.id === id);
    if (bank) {
      if (bank.is_mobilemoney) {
        return `${bank.slug} Phone number (length ${bank.acct_length})`;
      }
      return `${bank.slug} Account number (length ${bank.acct_length})`;
    }
    return 'Account Identifier';
  };
  return (
    <>
      <AuthHeader />
      <div className="w-full px-[26px] mx-auto">
        <div className="w-full mt-2 flex flex-col justify-center items-center">
          <div className="w-full sm:w-2/3 md:w-1/3 my-4">
            <p className="text-[24px] font-light">
              Create your community
              <br /> account
            </p>
          </div>
          {error && (
            <Alert sx={{ mb: 1, width: '100%' }} severity="error">
              {error.message}
            </Alert>
          )}
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={createCommunityValidationSchema}
          >
            {({ values, setFieldValue, ...other }) => {
              return (
                <Form className="w-full sm:w-2/3 md:w-1/3 flex flex-col gap-4">
                  <div className="">
                    <Field
                      value={values.name}
                      name="name"
                      type="text"
                      placeholder="Community Name"
                      className="px-5 w-full h-[69px] bg-[#F5F5F5] hover:border-none outline-0"
                    />
                  </div>
                  <ErrorMessage name="name" component="div" className="error" />
                  <div className="">
                    <Field
                      value={values.bankCode}
                      name="bankCode"
                      type="text"
                      placeholder="Bank Name"
                      className="px-5 w-full h-[69px] bg-[#F5F5F5] hover:border-none outline-0"
                      as="select"
                    >
                      {banks.map((bank) => (
                        <option value={bank.id} key={bank.id}>
                          {bank.name}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <ErrorMessage name="bankCode" component="div" className="error" />
                  <div className="">
                    <Field
                      value={values.accountNumber}
                      name="accountNumber"
                      type="text"
                      placeholder={placeholderText(values.bankCode)}
                      className="px-5 w-full h-[69px] bg-[#F5F5F5] hover:border-none outline-0"
                    />
                  </div>
                  <ErrorMessage name="accountNumber" component="div" className="error" />
                  <div className="flex justify-between items-center">
                    <div>
                      <p className="text-[20px] font-normal">
                        Upload your <br /> logo
                      </p>
                    </div>
                    <div
                      className="rounded-full flex items-center justify-center w-[113px] h-[113px] bg-[#F6F6F6]"
                      style={{ position: 'relative', overflow: 'hidden' }}
                    >
                      <input
                        id="file"
                        name="file"
                        type="file"
                        style={{
                          // visibility: 'hidden',
                          opacity: 0,
                          width: '100%',
                          height: '100%',
                          position: 'absolute',
                          zIndex: 100,
                        }}
                        onChange={(event) => {
                          handleFileUpload(event);
                          setFieldValue('file', event.currentTarget.files[0]);
                        }}
                      />

                      <img
                        style={file ? { height: '100%', width: '100%' } : {}}
                        src={file ? file : cameraImg}
                        alt="upload"
                      />
                    </div>
                    <ErrorMessage name="file" component="div" className="error" />
                  </div>

                  <UtenticButton
                    btnType={'submit'}
                    btnText={loading ? 'Loading...' : 'Start Your Experience'}
                    btnClassName={'w-full h-[69px] mt-6'}
                    disabled={loading}
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default CreateCommunityAccountPage;
