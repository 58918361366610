export const fDate = (d) => {
  return new Date(d).toDateString();
};
export const fDate2 = (d) => {
  const options1 = { weekday: 'short', month: 'short', day: 'numeric' };
  return new Date(d || new Date()).toLocaleDateString('en-US', options1);
};
export const fDate3 = (d) => {
  return new Date(d).toUTCString();
};
export const fCurrency = (c, numOnly) => {
  return new Intl.NumberFormat('en-EN', { style: 'currency', currency: 'ETB' }).format(c);
};
