import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .min(3, 'firstName must be at least 3 characters')
    .max(50, 'firstName must be less than 50 characters')
    .required('firstName is required'),
  lastName: Yup.string()
    .trim()
    .min(3, 'lastName must be at least 3 characters')
    .max(50, 'lastName must be less than 50 characters')
    .required('lastName is required'),
  phoneNumber: Yup.string()
    .trim()
    .matches(/^(9|7)\d{8}$/, 'Phone number must be in the format +251#########')
    .required('PhoneNumber is required'),
  ticket: Yup.number()
    .min(1, 'Number of tickets must be at least 1')
    .max(20, 'Number of tickets must be less than 20')
    .required('Number of tickets is required'),
});
