import React from 'react';
import PropTypes from 'prop-types';

const UtenticButton = ({
  btnText,
  btnOnclick,
  btnClassName,
  btnType,
  disabled = false,
}) => {
  UtenticButton.propTypes = {
    btnText: PropTypes.string.isRequired,
    btnOnclick: PropTypes.func,
    btnClassName: PropTypes.string,
    btnType: PropTypes.string,
    disabled: PropTypes.bool,
  };
  return (
    <button
      type={btnType}
      disabled={disabled}
      style={{
        cursor: disabled ? 'not-allowed' : 'pointer',
      }}
      onClick={btnOnclick}
      className={`flex items-center justify-center ${
        disabled ? 'bg-[#f19f8c]' : 'bg-[#FF5733]'
      } w-[338px] h-[69px] rounded-[8px] text-center ${btnClassName}`}
    >
      <p className="text-[20px] font-normal text-white">{btnText}</p>
    </button>
  );
};

export default UtenticButton;
