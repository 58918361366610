import React, { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { IconButton, InputAdornment, useTheme } from '@mui/material';
import { Icon } from '@iconify/react';

const AutoComplete = ({ options, selectedOptions, onChange, placeholder, ...other }) => {
  const [inputValue, setInputValue] = useState('');
  const theme = useTheme();
  const handleInputChange = (event, value) => {
    setInputValue(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAddOption();
    }
  };

  const handleAddOption = () => {
    if (inputValue && !selectedOptions.includes(inputValue)) {
      onChange([...selectedOptions, inputValue]);
      setInputValue('');
    }
  };

  return (
    <div>
      <Autocomplete
        multiple
        options={options}
        value={selectedOptions}
        inputValue={inputValue}
        onChange={(event, value) => onChange(value)}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        freeSolo
        renderInput={(params) => {
          params.InputProps = {
            ...params.InputProps,
            endAdornment: (
              <InputAdornment sx={{ width: '2.5rem' }} position="end">
                <IconButton onClick={handleAddOption} edge="end">
                  <Icon icon="zondicons:add-solid" color={theme.palette.primary.main} />
                </IconButton>
              </InputAdornment>
            ),
          };
          return (
            <TextField
              variant="outlined"
              placeholder={placeholder}
              // InputProps={}
              {...params}
            />
          );
        }}
        {...other}
      />
    </div>
  );
};

export default AutoComplete;
