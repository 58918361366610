import React from 'react';

import UtenticLogo from '../../assets/icon/utentic-logo.svg';
import Thumbnail from '../../assets/img/thumbnail.png';
import './header.css';
import { useSelector } from 'react-redux';
import LazyImage from '../LazyImage';
import { useNavigate } from 'react-router-dom';

export default function Header() {
  const { user } = useSelector((state) => state.auth);
  const communityName = user.communities?.length ? user.communities[0].name : 'Error';
  const communityImage = user.communities?.length ? user.communities[0].image : Thumbnail;
  const navigator = useNavigate();

  return (
    <div className="header">
      <img
        src={UtenticLogo}
        alt="logo"
        className="utentic-logo"
        onClick={() => navigator('/')}
      />
      <div className="company-info">
        <div className="company-info-text">
          <span className="company-name1">{communityName}</span>
          <span className="company-person1">{user.fullName}</span>
        </div>
        <div className="company-logo">
          <LazyImage ratio="1/1" src={communityImage} />
        </div>
        {/* <img src={communityImage} alt="thumbnail"  className="company-logo" /> */}
      </div>
    </div>
  );
}
