import { createSlice } from '@reduxjs/toolkit';

const authInitialState = {
  user: null,
  message: '',
  accessToken: '',
  isLoading: false,
  isError: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    INIT_AUTH: (state, _action) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    INIT_VERIFY_OTP: (state, _action) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    INIT_GET_AUTH: (state, _action) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    INIT_LOGIN: (state, _action) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    INIT_ORGANIZER_AUTH: (state, _action) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    AUTH_SUCCESS: (state, action) => {
      return {
        ...state,
        user: action.payload.user,
        isLoading: false,
        message: action.payload.message,
      };
    },
    VERIFY_OTP_SUCCESS: (state, action) => {
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
        user: action.payload.data.user,
        loginSuccess: true,
      };
    },
    LOGIN_SUCCESS: (state, action) => {
      return {
        ...state,
        isLoading: false,
        user: action.payload.user,
        message: action.payload.message,
      };
    },
    GET_AUTH_SUCCESS: (state, action) => {
      return {
        ...state,
        isLoading: false,
        user: action.payload,
      };
    },
    GET_AUTH_ERROR: (state, _action) => {
      return {
        ...state,
        isError: true,
      };
    },
    ORGANIZER_AUTH_SUCCESS: (state, action) => {
      return {
        ...state,
        isLoading: false,
        user: action.payload.user,
        message: action.payload.message,
      };
    },

    AUTH_ERROR: (state, _action) => {
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    },
    VERIFY_OTP_ERROR: (state, action) => {
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
        isError: true,
      };
    },
    LOGIN_ERROR: (state, _action) => {
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    },
    ORGANIZER_AUTH_ERROR: (state, _action) => {
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    },

    CLEAR: (_state, _action) => {
      return {
        ...authInitialState,
      };
    },
  },
});

export const { actions: authAction, name: authSliceName } = authSlice;
export default authSlice.reducer;
