import React from 'react';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { ReactComponent as UtenticLogo } from '../../assets/icon/utentic-logo.svg';

import './modal.css';
import { useNavigate } from 'react-router-dom';
const contactNumber = '0963742925';

function Modal() {
  const navigate = useNavigate();
  return (
    <div className="alert">
      <div className="alert_top">
        <UtenticLogo onClick={() => navigate('/')} />
      </div>
      <div className="alert_bottom">
        <div className="text_left">
          <p>
            Thank you for booking. We will contact you soon. Call us if you have any
            questions.
          </p>
        </div>
        <div className="text__right ">
          <a className="icons" href={`tel:${contactNumber}`} style={{ color: 'white' }}>
            <BsFillTelephoneFill size={20} />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Modal;
