import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import TripDetailPage from './pages/TripDetailPage';
import Pay from './pages/Pay';
import Thankyou from './pages/Thankyou';
import Home from './pages/Home';
import Done from './pages/Done';
import Dashboard from './pages/Dashboard';
import TripCreatePage from './pages/TripCreatePage';
import TripEditPage from './pages/TripEditPage';
import BookingsPage from './pages/BookingsPage';
import ReceiptPage from './pages/ReceiptPage';
import ErrorPage from './pages/ErrorPage';
import UserAuthPage from './pages/auth/user/UserAuthPage';
import OrganizerLoginPage from './pages/auth/organizer/OrganizerLoginPage';
import OrganizerSignupPage from './pages/auth/organizer/OrganizerSignupPage';
import VerifyCodePage from './pages/auth/components/VerifyCodePage';
import CreateCommunityAccountPage from './pages/auth/organizer/CreateCommunityAccountPage';
import {
  Alert,
  Box,
  CircularProgress,
  Snackbar,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import { grey, orange } from '@mui/material/colors';
import { AxiosAPI } from './config';
import Tickets from './pages/Tickets';
import { authAction } from './redux/slice/auth';
import { put } from 'redux-saga/effects';
import { useDispatch, useSelector } from 'react-redux';
import { SnackbarContext } from './utils/SnackbarContext';
import ReactGA from 'react-ga4';
import ExperienceCard from './pages/Home/ExperienceCard';
import Experience from './pages/Experience/Experience';
import ExperienceDetail from './pages/TripDetailPage/ExperienceDetail';

const protectedRoutes = [
  'tickets',
  'booking',
  'dashboard',
  'create-trip',
  'edit-trip',
  'receipt',
  'create-account',
  'pay',
  'done',
  'thankyou',
];
const checkIfProtected = (location = false) => {
  const href = location || window.location.href;
  return protectedRoutes.find((route) => href.includes(route));
};
function App() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const { user, isError } = useSelector((state) => state.auth);
  const [snack, setSnack] = useState({
    message: '',
    severity: 'error',
    open: false,
  });
  const openSnack = (message, severity = 'error') => {
    setSnack({ message, severity, open: true });
  };
  const handleCloseSnack = () => {
    setSnack({
      message: '',
      severity: 'error',
      open: false,
    });
  };
  const location = useLocation();
  useEffect(() => {
    if (location.pathname && checkIfProtected(location.pathname)) {
      dispatch(authAction.INIT_GET_AUTH({}));
    }
  }, [location]);
  useEffect(() => {
    if (loading && checkIfProtected()) {
      dispatch(authAction.INIT_GET_AUTH({}));
    } else {
      setLoading(false);
    }
  }, [loading]);
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/', title: 'Home page', user: user });
  }, []);
  useEffect(() => {
    // if (isError) {
    // window.location.assign('/login');
    // }
    if (user) {
      setLoading(false);
    }
  }, [user, isError]);
  const primaryColor = orange;
  const theme = createTheme({
    typography: {
      fontFamily: 'Rubik, sans-serif',
    },
    palette: {
      primary: {
        ...primaryColor,
        main: '#FF5733',
      },
      secondary: grey,
      mode: 'light',
    },
  });
  if (error) return <h2>There was a problem with our server</h2>;
  if (loading) {
    return (
      <Box sx={{ display: 'flex', py: 10, justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <SnackbarContext.Provider value={{ snack, setSnack, openSnack }}>
      <Snackbar open={snack.open} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert severity={snack.severity}>{snack.message}</Alert>
      </Snackbar>
      <ThemeProvider theme={theme}>
        <div className="App">
          <Routes>
            <Route path="/tickets" element={<Tickets />} />

            <Route path="/booking" element={<BookingsPage />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/create-trip" element={<TripCreatePage />} />
            <Route path="/edit-trip" element={<TripEditPage />} />
            <Route path="/receipt/:receipt" element={<ReceiptPage />} />
            <Route path="/trip/:id" element={<TripDetailPage />} />
            <Route path="/login" element={<UserAuthPage />} />
            <Route path="/signup" element={<UserAuthPage />} />
            <Route path="/verify" element={<VerifyCodePage />} />
            <Route path="/organizer/login" element={<OrganizerLoginPage />} />
            <Route path="/organizer/signup" element={<OrganizerSignupPage />} />
            <Route path="/experience" element={<Experience />} />
            <Route path="/experience/:id" element={<ExperienceDetail />} />
            
            <Route
              path="/organizer/create-account"
              element={<CreateCommunityAccountPage />}
            />

            <Route
              path="/pay/:id"
              element={<Pay checkIfProtected={checkIfProtected} />}
            />
            <Route path="/done/:id" element={<Done />} />
            <Route path="/thankyou" element={<Thankyou />} />
            <Route path="/" element={<Home />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </div>
      </ThemeProvider>
    </SnackbarContext.Provider>
  );
}

export default App;
