import React from 'react';
import './style.css';

export default function Analytics({ stat }) {
  return (
    <div className="analytics-container">
      <h2 style={{ paddingBottom: '1rem' }}>Analytics</h2>
      <div className="trip-grid">
        <div className="grid-box">
          <div className="grid-box-num">{stat.tripsCount}</div>
          <div className="grid-box-text">Trips</div>
        </div>
        <div className="grid-box">
          <div className="grid-box-num">{stat.moneyGenerated}</div>
          <div className="grid-box-text">Birr Granted</div>
        </div>
        <div className="grid-box">
          <div className="grid-box-num">{stat.bookings}</div>
          <div className="grid-box-text">Bookings</div>
        </div>
        <div className="grid-box">
          <div className="grid-box-num">{stat.paidBookings}</div>
          <div className="grid-box-text">Paid Bookings</div>
        </div>
      </div>
    </div>
  );
}
