import React, { useEffect, useState } from 'react';
import {
  Alert,
  Autocomplete,
  Box,
  Chip,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import Button from '@mui/material/Button';

import './style.css';
import { Formik } from 'formik';
import { createTripValidation } from '../../pages/auth/organizer/validation';
import { AxiosAPI } from '../../config';
import CustomAutoComplete from '../AutoComplete';
import { Icon } from '@iconify/react';
import ImageUpload from '../ImageUpload';
import { useNavigate } from 'react-router-dom';

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export default function TripForm() {
  const [categories, setCategories] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [additional, setAdditional] = useState({ packageIncludes: [], activities: [] });
  const [loading, setLoading] = useState(false);
  const [err, setError] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    AxiosAPI({
      url: '/categories',
      method: 'GET',
    })
      .then((d) => d.data.data)
      .then(setCategories);
  }, []);
  useEffect(() => {
    AxiosAPI({
      url: '/destinations',
      method: 'GET',
    })
      .then((d) => d.data.data)
      .then((d) => setDestinations(d.map((e) => e.name)));
  }, []);
  useEffect(() => {
    AxiosAPI({
      url: '/trips/packagesAndActivities',
      method: 'GET',
    })
      .then((d) => d.data.data)
      .then((d) => ({
        packageIncludes: d.packageIncludes.map((p) => p.name),
        activities: d.activities.map((p) => p.name),
      }))
      .then(setAdditional);
  }, []);
  const handleSubmit = (values) => {
    const formData = new FormData();
    for (const i in values) {
      if (i === 'images') {
        values[i].map((file) => {
          formData.append(i, file.file);
        });
      } else {
        formData.append(i, values[i]);
      }
    }
    setLoading(true);
    setError({});
    AxiosAPI({
      url: '/trips',
      method: 'POST',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((e) => {
        if (e.status == 200 || e.response.status == 200) {
          navigate('/dashboard');
          return;
        }
        setError({ external: e.response.data.message });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };
  return (
    <Formik
      initialValues={{
        name: '',
        departure: '',
        return: '',
        description: '',
        destination: '',
        price: '',
        meetUpLocation: '',
        categoryId: '',
        packageIncludes: [],
        activities: [],
        isChildFriendly: true,
        discountAmount: '',
        discountPeople: '',
        images: [],
      }}
      onSubmit={handleSubmit}
      validationSchema={createTripValidation}
    >
      {({ values, handleSubmit, handleChange, errors, setFieldValue }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className="form-container">
              <TextField
                fullWidth
                color="warning"
                label={'Trip Name'}
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.07)',
                  color: 'rgba(0, 0, 0, 0.38)',
                }}
                name="name"
                onChange={handleChange}
                value={values.name}
              />
              <TextField
                fullWidth
                color="warning"
                label={'Departure Date'}
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.07)',
                  color: 'rgba(0, 0, 0, 0.38)',
                }}
                type="datetime-local"
                InputLabelProps={{
                  shrink: true,
                }}
                name="departure"
                onChange={handleChange}
                value={values.departure}
              />
              <TextField
                fullWidth
                color="warning"
                label={'Return Date'}
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.07)',
                  color: 'rgba(0, 0, 0, 0.38)',
                }}
                type="datetime-local"
                InputLabelProps={{
                  shrink: true,
                }}
                min={values.departure}
                name="return"
                onChange={handleChange}
                value={values.return}
              />
              <TextField
                color="warning"
                multiline
                rows={4}
                label={'Description'}
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.07)',
                  color: 'rgba(0, 0, 0, 0.38)',
                }}
                name="description"
                onChange={handleChange}
                value={values.description}
              />
              <div className="form-inner">
                {/* <div className="form-inner-header">Destination/Location</div> */}

                <Autocomplete
                  options={destinations}
                  value={values.destination || ''}
                  inputValue={values.destination || ''}
                  onChange={(event, value) => setFieldValue('destination', value)}
                  onInputChange={(event, value) => setFieldValue('destination', value)}
                  getOptionLabel={(v) => v}
                  freeSolo
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      color="warning"
                      label="Destination/Location"
                      sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.07)',
                        color: 'rgba(0, 0, 0, 0.38)',
                      }}
                      name="price"
                      {...params}
                    />
                  )}
                />
              </div>

              <TextField
                fullWidth
                color="warning"
                label={'Price'}
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.07)',
                  color: 'rgba(0, 0, 0, 0.38)',
                }}
                name="price"
                type="number"
                onChange={handleChange}
                value={values.price}
              />
              <TextField
                fullWidth
                color="warning"
                label={'Meetup Location'}
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.07)',
                  color: 'rgba(0, 0, 0, 0.38)',
                }}
                name="meetUpLocation"
                onChange={handleChange}
                value={values.meetUpLocation}
              />
              <div className="form-inner">
                <div className="form-inner-header">Package Includes</div>
                <CustomAutoComplete
                  name="packageIncludes"
                  options={additional.packageIncludes}
                  selectedOptions={values.packageIncludes}
                  onChange={(val) => setFieldValue('packageIncludes', val)}
                  placeholder="Enter package"
                />
              </div>
              <div className="form-inner">
                <div className="form-inner-header">Activities</div>
                <CustomAutoComplete
                  name="activities"
                  options={additional.activities}
                  selectedOptions={values.activities}
                  onChange={(val) => setFieldValue('activities', val)}
                  placeholder="Enter activity"
                />
              </div>
              <div>
                <TextField
                  fullWidth
                  color="warning"
                  label="Category"
                  sx={{
                    backgroundColor: 'rgba(0, 0, 0, 0.07)',
                    color: 'rgba(0, 0, 0, 0.38)',
                  }}
                  select
                  name="categoryId"
                  SelectProps={{
                    native: false,
                  }}
                  helperText="Let us know if you want more categories"
                  onChange={handleChange}
                  // value={values.categoryId}
                  defaultValue={'DEFAULT'}
                >
                  <MenuItem value="DEFAULT" disabled>
                    Choose a category...
                  </MenuItem>
                  {categories.map((option) => (
                    <MenuItem value={option.id} key={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="form-inner">
                <div
                  className="form-inner-header"
                  style={{
                    color: 'rgba(0, 0, 0, 0.5)',
                  }}
                >
                  Is child friendly?
                </div>
                <IOSSwitch sx={{ m: 1 }} defaultChecked name="isChildFriendly" />
              </div>
              <div className="form-inner">
                <div
                  className="form-inner-header"
                  style={{
                    color: 'rgba(0, 0, 0, 0.5)',
                    marginBottom: '1em',
                  }}
                >
                  Discount?
                </div>
                <div style={{ display: 'flex', gap: '1em' }}>
                  <TextField
                    color="warning"
                    label={'Discount Amount'}
                    sx={{
                      backgroundColor: 'rgba(0, 0, 0, 0.07)',
                      color: 'rgba(0, 0, 0, 0.38)',
                    }}
                    name="discountAmount"
                    type="number"
                    value={values.discountAmount}
                    onChange={handleChange}
                  />
                  <TextField
                    color="warning"
                    label={'Amount of People'}
                    sx={{
                      backgroundColor: 'rgba(0, 0, 0, 0.07)',
                      color: 'rgba(0, 0, 0, 0.38)',
                      visibility: 'hidden',
                    }}
                    name="discountPeople"
                    type="number"
                    value={values.discountPeople}
                    onChange={handleChange}
                    disabled
                  />
                </div>
              </div>

              <ImageUpload
                images={values.images}
                setImages={(val) => setFieldValue('images', val)}
              />
              {Object.keys(Object.assign({}, err, errors)).map((error, i) => (
                <Alert severity="error" key={i}>
                  {Object.assign({}, err, errors)[error]}
                </Alert>
              ))}
              <Button
                variant="contained"
                sx={{
                  marginTop: '2em',
                  backgroundColor: '#FF5733',
                }}
                type="submit"
                disabled={loading}
              >
                {loading ? 'Loading...' : 'Save'}
              </Button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
}
