import axios from 'axios';
export const baseURL = 'https://new.utentic.app/api';
// export const baseURL = 'https://new.utentic.app/api';
const httpClient = axios.create({
  // baseURL: 'http://172.20.10.3:4455/api',
  baseURL,
  // baseURL: process.env.APP_API_BASE_URL,
});

// httpClient.interceptors.request.use(function (config) {
//   // const token = localStorage.getItem('accessToken');
//   // config.headers.Authorization = token ? `Bearer ${token}` : '';
//   return config;
// });
// axios.defaults.baseURL = 'https://api.utentic.app/api';
export const AxiosAPI = async ({ url, method, data, headers = {} }) => {
  return await httpClient({
    url,
    method,
    data,
    headers,
  }).catch((err) => {
    // openSnack(err.response.data.message)
    if (err.response.status === 401) {
      window.location.assign('/signup');
    }
    return err;
  });
};
