import React from 'react';
import { ReceiptLongOutlined, PersonOutline } from '@mui/icons-material';
import './style.css';
import { useNavigate } from 'react-router-dom';

export default function BCard({ tnx }) {
  const navigate = useNavigate();
  return (
    <div className="bcard">
      <div className="bcard-left">
        <PersonOutline
          sx={{
            color: '#FF5733',
            fontSize: '3em',
          }}
        />
      </div>
      <div className="bcard-mid">
        <div className="bcard-mid-name">{tnx.user.fullName}</div>
        {tnx.user?.emergencyNumber && (
          <div className="bcard-mid-number">
            Emergency Number: {tnx.user.emergencyNumber}
          </div>
        )}
        <div className="bcard-mid-actions">
          <button
            style={{
              color: 'white',
              backgroundColor: '#FF5733',
              border: 'none',
              padding: '0.1em 1em',
              borderRadius: '0.5em',
            }}
          >
            {tnx.isPaid ? 'Paid' : 'Booked'}
          </button>
          {tnx.tnxRef && (
            <ReceiptLongOutlined
              sx={{
                color: '#FF5733',
              }}
              onClick={() => navigate('/receipt/' + tnx.tnxRef)}
            />
          )}
        </div>
      </div>
      <a href={`tel:${tnx.user.phoneNumber}`}>
        <div className="bcard-right">
          <button className="bcard-call-btn">call</button>
        </div>
      </a>
    </div>
  );
}
